<template>
  <div :class="simpleSearchClass">
    <simple-autocomplete ref                = "input"
                         endpoint           = "/api/autocomplete/suggest"
                         :input-class       = "form-control"
                         :show-autocomplete = "toBoolean(showAutocomplete)"
                         parameter-name     = "search"
                         :placeholder       = "placeholder"
                         :text              = "internalText"
                         @click             = "onClick"
                         @key-up-enter      = "onKeyUpEnter"
                         :class             = "cssClass">
    </simple-autocomplete>
    <span :class="iconBoxClasslist" v-show="showButton">
      <button @click.prevent = "navigateToUrl(autocompleteText)"
              aria-label     = "search button"
              type           = "button"
              :class         = "iconButtonClasslist">
        <i class="bi bi-search"></i>
      </button>
    </span>
  </div>
</template>

<script>
  import SimpleAutocomplete            from '../components/simple_autocomplete.vue'
  import { blank, present, toBoolean } from '../lib/utils.js'

  export default {
    components: {
      SimpleAutocomplete
    },

    props: {
      // Public: Whether to automatically focus when rendered.
      autofocus:        { default: false },
      // Class to apply to the top element in the component.
      cssClass:         { default: null },
      // Styling paradigm to use for the component
      mode:             { default: null },
      // Placeholder text.
      placeholder:      { default: 'Site Search' },
      // Querystring to apply to the search url.
      querystring:      { default: '' },
      // True to display a button to the right of the search textbox.
      showButton:       { default: true },
      // Whether or not to show the autocomplete when the user types
      showAutocomplete: { default: true },
      // Search terms.
      text:             { default: '' },
      // Redirect path for search results
      searchPath:       { default: '/search/' },
      // Overlays the search icon with blue backdrop on the input if true
      overlayedIcon:    { default: false }
    },

    // internalText: mutable state for text property.
    // maxLength:    maximum search term length.
    data() {
      return {
        internalText: this.text,
        maxLength:    150
      }
    },

    computed: {
      // Public: Return the text in the autocomplete component.
      autocompleteText() {
        return this.$refs.input.internalText
      },

      // Public: Css class to apply to the top level element.
      simpleSearchClass() {
        let additionalCss = this.cssClass

        if (this.mode === "compact") {
          additionalCss = `${additionalCss} simple-search-compact-styling`
        }

        return `${additionalCss} simple-search input-group`
      },

      // Public: Returns a classlist for the box containing the search magnifying glass icon
      iconBoxClasslist() {
        return toBoolean(this.overlayedIcon) ? "search-icon-overlayed" : ""
      },

      iconButtonClasslist() {
        const baseButtonClasslist = "background-color-nav-blue border-0 ms-2 fs-4 text-white"

        return toBoolean(this.overlayedIcon) ? `search-icon-backdrop ${baseButtonClasslist}` : `${baseButtonClasslist}`
      }
    },

    methods: {
      toBoolean: toBoolean,

      // Public: Navigate to the search url with the given query.
      navigateToUrl(query) {
        const searchValue = query

        if (blank(searchValue)) {
          return
        }

        let path = `${this.searchPath}${searchValue}`

        if (present(this.querystring)) {
          path = `${path}?${this.querystring}`
        }

        document.location.href = path
      },

      // Handle autocomplete click event.
      onClick(item) {
        this.navigateToUrl(item.value)
      },

      onKeyUpEnter(item) {
        this.navigateToUrl(this.autocompleteText)
        this.$refs.input.hideDropdown()

        // Turn off the auto complete typing checker.
        clearInterval(this.$refs.input.typingIntervalId)
      }
    },

    mounted() {
      if (this.autofocus) {
        this.$refs.input.focus()
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/stylesheets/colors.scss";
  @import "../../assets/stylesheets/menus.scss";

  .search-icon-overlayed {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    @media (max-width: $breakpoint-lg) {
      display: none;
    }

    > .search-icon-backdrop {
      height: 100%;
    }
  }

  .search-icon-backdrop {
    padding: 0em .8em 0em .8em;
    border-radius: 0rem .5rem .5rem 0rem; /* top-left, top-right, bottom-right, bottom-left */

    > i {
      font-size: 1.25rem;
    }

    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  // Modifies <simple-search> by adding this single class to be a minimal look/feel
  .simple-search-compact-styling {
    width: 100%;

    // Border around search bar
    > .simple-autocomplete > input {
      border-style: none none solid none !important;
      padding-left: 3.8rem !important;
      padding-bottom: .6rem !important;
      border-radius: 0rem;
      font-weight: 575;
      color: $scrollbar-gray;

      &::placeholder {
        color: $lightest-secondary;
      }

      &:focus {
        box-shadow: none;
      }
    }

    // Search bar button
    > span > button {
      position: absolute;
      background-color: rgba(0,0,0,.0) !important;
      left: 0;

      > i {
        font-size: 1.4rem;
        color: $scrollbar-gray;
      }
    }
  }
</style>
